//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getBanners,
         getDictionary,
         retrieveButter } from '~/utils/butterUtils'
import { mapState, mapMutations } from "vuex"

function detectIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  } // IE 10 or older => return version number
  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    var rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  } // IE 11 => return version number
  return false;
}

export default {
  head() {
    return {
      script: [
        /*
          2023-02-15 John Yee:
            Remove trackers because not using them right now.
            Commenting them out rather than deleting so they are easy to turn back on if needed.
        */

        /*
          2024-04-04 John Yee:
            Enabling FaceBook pixel per request from Shari as a result of DA Mexico campaign.

            note: In nuxt.config.js "modules: [...]"" section the Pixel id is 744840838985070,
                  which is not the same as the one used in the original 'innerHTML:' code.
                  I don't know who got these ids.  I don't know why we have two different ones.

                  Here is the legacy code from nuxt.config.js
                  // [
                  //   'nuxt-facebook-pixel-module',
                  //   {
                  //     / * module options * /
                  //     track: 'PageView',
                  //     pixelId: '744840838985070',
                  //     disabled: false
                  //   }
                  // ],

          2024-04-23 John Yee:
            Pause Pixel fires initially until cookie consent is given
            see https://developers.facebook.com/docs/meta-pixel/implementation/gdpr

          2024-04-29 John Yee
            original code: fbq('init', '1625627407857277');
            Hope Bradberry said Facebook Pixel used id '744840838985070' in 2020 and 2022;
            hence, revising the id in fbq('init', ...).
        */
        {
          innerHTML: `!function(f,b,e,v,n,t,s) {
          if (f.fbq) return;
          n=f.fbq=function() {n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)};
          if (!f._fbq) f._fbq=n;
          n.push=n;
          n.loaded=!0;
          n.version='2.0';
          n.queue=[];
          t=b.createElement(e);
          t.async=!0;
          t.src=v;
          s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)
          } (window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('consent', 'revoke');
          fbq('init', '744840838985070');
          fbq('track', 'PageView');`,
          type: "text/javascript",
        },

        /**
         * 2024-08-04 John Yee
         * Google Tag Manager for supporting Zemanta
         */
        {
          innerHTML: `!(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-MH69ZJ2L');`,
          type: "text/javascript",
        },

        //
        // {
        //   innerHTML: `_linkedin_partner_id = "2638290";
        //   window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        //   window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
        //   type: "text/javascript",
        // },
        // {
        //   innerHTML: `(function(l) {
        //   if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        //   window.lintrk.q=[]}
        //   var s = document.getElementsByTagName("script")[0];
        //   var b = document.createElement("script");
        //   b.type = "text/javascript";b.async = true;
        //   b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        //   s.parentNode.insertBefore(b, s);})(window.lintrk);`,
        //   type: "text/javascript",
        // },
        // {
        //   innerHTML: `(function(){var s = document.getElementsByTagName("script")[0];
        //   var b = document.createElement("script");
        //   b.type = "text/javascript";
        //   b.async = true;
        //   b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        //   s.parentNode.insertBefore(b, s);
        //   })();`,
        //   type: "text/javascript",
        // },
        //
        // {
        //   innerHTML: `!function(_window, _document) {
        //   var ZEM_TAG_ID='7069';
        //   if (_window.zemApi) {var toArray = function(object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.zemApi.marketerId = toArray(_window.zemApi.marketerId).concat(toArray(ZEM_TAG_ID));return;}
        //   var api = _window.zemApi = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.0';api.loaded = true;api.marketerId = ZEM_TAG_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = 'https://js-tag.zemanta.com/zcpt.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);
        //   }(window, document);
        //   zemApi('track', 'PAGE_VIEW');`,
        //   type: "text/javascript",
        // },
      ],
      noscript: [
        //2024-04-25 John Yee: Do not enable Facebook Pixel in "noscript", otherwise may be enabling FB for users without their consent
        //                     In fact, it may be a good idea not to enable any trackers in "noscript" for this reason.
        //                     see https://support.cookiebot.com/hc/en-us/articles/360004461894-Require-consent-before-loading-Facebook-pixel
        // {
        //   innerHTML: `<img height="1" width="1" src="https://www.facebook.com/tr?id=1625627407857277&ev=PageView&noscript=1"/>`,
        //   type: "text/javascript",
        // },
        //
        // {
        //   innerHTML: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=2638290&fmt=gif" />`,
        //   type: "text/javascript",
        // },
        //
        // {
        //   innerHTML: `<img src="https://p1.zemanta.com/v2/p/ns/7069/PAGE_VIEW/" referrerpolicy="no-referrer-when-downgrade" height="1" width="1" border="0" alt="" />`,
        //   type: "text/javascript",
        // },

        /**
         * 2024-08-04 John Yee
         * Google Tag Manager for supporting Zemanta
         */
        {
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH69ZJ2L" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          type: "text/javascript",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
    };
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'layouts/default.vue asyncData ')
  },
  data() {
    return {
      isAccessibilityMenuActive: false,
      selectedLanguage: this.$route.name.split("-").pop(),
      isMobileMenuActive: false,
      optedIn: true,
      isCookieConsentBannerActive: true,
      device: {},
    };
  },
  computed: {
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    langHyphen () {
      return this.lang.slice(0,2)+"-"+this.lang.slice(2).toUpperCase()
    },
    isSandboxSite() {
      return process.env.stage === "dev";
    },
    showSandboxWarning() {
      return this.$store.state.showSandboxWarning;
    },
    privacyRoute() {
      return !/request|index|dashboard/i.test(this.$route.name);
    },
    offline() {
      return process.env.offline && process.env.offline !== "false";
    },
    isBgImage: function () {
      return (
        this.$route.name &&
        this.$route.name.includes("index")
      );
    },
    banners () {
      let b = []
      // banners appear on only the landing page
      if (this.$route.name==='index-enUS' || this.$route.name==='index-esES') {
        let isProductionSite = process.env.stage === 'prod'
        b = getBanners(this.$store.state.butterBanners, this.lang, isProductionSite)
      }
      return b
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    ...mapState(["isPrivacyOptInModalActive"]),
  },
  watch: {
    isPrivacyOptInModalActive(val) {
      // console.log(this.$cookie.get('vfaOptIn'))
      if (!this.$cookie.get("vfaOptIn")) {
        this.optedIn = false;
      } else this.optedIn = true;
    },
  },
  filters: {
    truncate: function (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
    },
  },
  methods: {
    anonymousStart () {
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: "is-success",
        duration: 8000,
      });
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit("clearVoterData");
      this.$store.dispatch("userdata/clearVoterData");
      this.$router.push(
        this.localePath({
          name: "request-stage",
          params: { stage: "start-fpca" },
        })
      );
    },
    onChangeLanguage(event) {
      this.$router.replace(this.switchLocalePath(event));
      this.selectedLanguage = event;
    },
    optIn() {
      this.$cookie.set("vfaOptIn", true, 1);
      this.optedIn = true;
    },
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
    ...mapMutations(["togglePrivacyModalActiveState"]),
  },
  async mounted() {
    let routeLanguage = this.$route.name.split("-").pop();
    if (routeLanguage !== this.$i18n.locale) {
      this.onChangeLanguage(routeLanguage);
    }

    // console.log('-', process.env.siteName, process.env.stage)
    if (!this.$cookie.get("vfaOptIn")) {
      this.optedIn = false;
    }
    // console.log('process.env.stage:', process.env.stage)
    // this.$snackbar.open({
    //   message: process.env.stage === 'dev' ? 'You are on the SANDBOX site. Messages will only be sent to your address (Your LEO will NOT receive your FPCA.)' : 'This beta site is now live.  If you submit an FPCA it will be sent to your Election Official.',
    //   type: 'is-warning',
    //   position: this.optedIn ? 'is-top' : 'is-bottom',
    //   actionText: 'I Understand',
    //   indefinite: true
    // })
    function checkDeviceSupport(callback) {
      if (!canEnumerate) {
        return;
      }
      if (
        !navigator.enumerateDevices &&
        window.MediaStreamTrack &&
        window.MediaStreamTrack.getSources
      ) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
          window.MediaStreamTrack
        );
      }
      if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
      }
      if (!navigator.enumerateDevices) {
        if (callback) {
          callback();
        }
        return;
      }
      MediaDevices = [];
      navigator.enumerateDevices(function (devices) {
        devices.forEach(function (_device) {
          var device = {};
          for (var d in _device) {
            device[d] = _device[d];
          }
          if (device.kind === "audio") {
            device.kind = "audioinput";
          }
          if (device.kind === "video") {
            device.kind = "videoinput";
          }
          var skip;
          MediaDevices.forEach(function (d) {
            if (d.id === device.id && d.kind === device.kind) {
              skip = true;
            }
          });
          if (skip) {
            return;
          }
          if (!device.deviceId) {
            device.deviceId = device.id;
          }
          if (!device.id) {
            device.id = device.deviceId;
          }
          if (!device.label) {
            device.label = "Please invoke getUserMedia once.";
            if (!isHTTPs) {
              device.label =
                "HTTPs is required to get label of this " +
                device.kind +
                " device.";
            }
          } else {
            if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
              isWebcamAlreadyCaptured = true;
            }
            if (device.kind === "audioinput" && !isMicrophoneAlreadyCaptured) {
              isMicrophoneAlreadyCaptured = true;
            }
          }
          if (device.kind === "audioinput") {
            hasMicrophone = true;
          }
          if (device.kind === "audiooutput") {
            hasSpeakers = true;
          }
          if (device.kind === "videoinput") {
            hasWebcam = true;
          }
          MediaDevices.push(device);
        });
        if (callback) {
          callback();
        }
      });
    }
    if (process.browser) {
      window.onNuxtReady((app) => {
        let isIE = detectIE();
        this.$store.commit("userdata/updateDevice", { isIE });
        /*
        app.$intercom.boot()
        */
      });
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        // Firefox 38+ seems having support of enumerateDevicesx
        navigator.enumerateDevices = function (callback) {
          navigator.mediaDevices.enumerateDevices().then(callback);
        };
      }
      var MediaDevices = [];
      var isHTTPs = location.protocol === "https:";
      var canEnumerate = false;

      if (
        typeof MediaStreamTrack !== "undefined" &&
        "getSources" in MediaStreamTrack
      ) {
        canEnumerate = true;
      } else if (
        navigator.mediaDevices &&
        !!navigator.mediaDevices.enumerateDevices
      ) {
        canEnumerate = true;
      }
      var hasMicrophone = false;
      var hasSpeakers = false;
      var hasWebcam = false;

      var isMicrophoneAlreadyCaptured = false;
      var isWebcamAlreadyCaptured = false;

      // check if device allows capture via the file input field
      var i = document.createElement("input");
      i.setAttribute("capture", true);
      var inputCaptureSupported = !!i["capture"];
      checkDeviceSupport(() => {
        this.$store.commit("userdata/updateDevice", {
          hasWebCam: hasWebcam,
          hasMicrophone: hasMicrophone,
          hasSpeakers: hasSpeakers,
          isMicrophoneAlreadyCaptured: isMicrophoneAlreadyCaptured,
          isWebcamAlreadyCaptured: isWebcamAlreadyCaptured,
          inputCaptureSupported: inputCaptureSupported,
        });
      });
      if (navigator.mediaDevices) {
        navigator.mediaDevices.ondevicechange = () => {
          checkDeviceSupport((event) => {
            this.$store.commit("userdata/updateDevice", {
              hasWebCam: hasWebcam,
              hasMicrophone: hasMicrophone,
              hasSpeakers: hasSpeakers,
              isMicrophoneAlreadyCaptured: isMicrophoneAlreadyCaptured,
              isWebcamAlreadyCaptured: isWebcamAlreadyCaptured,
            });
          });
        };
      }
      await import("current-device").then(({ default: device }) => {
        this.$store.commit("userdata/updateDevice", {
          type: device.type,
          os: device.os,
          orientation: device.orientation,
        });
        window.onresize = () => {
          this.$store.commit("userdata/updateDevice", {
            orientation:
              window.innerWidth > window.innerHeight ? "landscape" : "portrait",
          });
        };
      });
    }
  },
};
